<template>
    <div class="payCheckWrap">
        <div class="payCheckText" v-if="backIndex !='free'">请确认支付是否成功</div>
        <div class="payCheckText" v-if="backIndex =='free'">订购成功</div>
        <div class="payBtnModule">
            <div class="surePay" @click="goAddUser"  v-if="backIndex !='free'">已完成支付</div>
            <div class="surePay" @click="goAddUser" v-if="backIndex =='free'">完善出游信息</div>
            <div class="backSy" @click="goSY">返回首页</div>
        </div>
    </div>
</template>
<style scoped>
.payCheckWrap{
    width: 7.5rem;
    height: 100vh;
    background: #f8f8f8;
}
.payCheckText{
    width: 7.5rem;
    height: 4.9rem;
    text-align: center;
    line-height: 4.9rem;
    font-size: 0.32rem;
    color: #333333;
    margin-bottom: 0.65rem;
}
.payBtnModule{
    width: 7.5rem;
    height: 0.85rem;
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
}
.surePay{
    width: 2.8rem;
    height: 0.85rem;
    font-size: 0.32rem;
    color: #f8f8f8;
    text-align: center;
    line-height: 0.85rem;
    border-radius: 0.43rem;
    background-image: linear-gradient(to right,#ee9047,#f1ab4b);
}
.backSy{
    width: 2.8rem;
    height: 0.85rem;
    font-size: 0.32rem;
    color: #f8f8f8;
    text-align: center;
    line-height: 0.85rem;
    border-radius: 0.43rem;
    background-image: linear-gradient(to right,#e9402d,#e74e44);
}
</style>
<!-- 610000201801112315 -->
<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      backIndex:0,
      
    };
  },
  components: {},
  created() {
     this.backIndex = localStorage.getItem('backIndex');
  },
  mounted() {},
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    goAddUser(){
        const {bizId} = this.$route.query;
        this.request.get('/api/n/trip/pay/query',{
            bizId:bizId
        }).then(res=>{
            console.log(res)
            const {bizId,buyCount} = res;
            if(bizId&&buyCount){
               this.$router.push({path:'/h/n/upUserInfo',query:{count:buyCount,bizId:bizId}})  
            }
        })
    },
    goSY(){
      let backIndex = localStorage.getItem('backIndex')
      let channel = localStorage.getItem('channel')
      let path ="/h/n/yntrip"; 
      if(backIndex == 'collect'){
        this.$router.push({path:'/h/n/ynTripCollect', query:{channel:channel}});
        return
      }
      if(backIndex=='1'){
        path = "/h/n/yntripOne"
      }else if(backIndex=='2'){
        path = "/h/n/yntripTwo"
      }else if(backIndex == 'free') {
        path = "/h/n/ynTripFree"
      }
        this.$router.push({path:path})

        
    }
  },
};
</script>